import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';

const DropInForm = () => {
  // Get dropInID from localStorage, fallback to location.state if not found
  const dropInID = localStorage.getItem('dropInID');
  console.log('dropInID', dropInID);

  useEffect(() => {
    if (dropInID) {
      loadWidget(dropInID);
    }
  }, [dropInID]);

  const loadWidget = (dropInID) => {
    if (!dropInID) {
      return;
    }
    window.initializeDropIn(dropInID, true);
  };

  return (
    <Container fluid className="p-0 px-md-3 py-md-4 container-fluid h-100">
      <div className="dropin-container"></div>
    </Container>
  );
};

export default DropInForm;
