import { useState } from 'react';

export default function useStep(initialStep) {
  const [activeStep, setActiveStep] = useState(initialStep);

  const handleStepNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleStepPrevious = () => {
    setActiveStep(activeStep - 1);
  };

  return {
    activeStep,
    onNext: handleStepNext,
    onPrev: handleStepPrevious,
  };
}
