import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';
import GeneralForm from 'forms/general';
import { services, mainRoutes } from 'json';
import Home from 'Home.js';
import Success from 'Success.js';
import MainForm from 'forms/general/combinedForm';
import MainFormConditioned from 'forms/general/combinedFormConditioned';
import DropInForm from 'forms/dropIn';

const newRoute = {
  path: '/exterior-painting-staining',
  title: 'Choose Your Needed Exterior Painting and Staining?',
  task: [
    {
      path: 'A',
      name: 'More than half of the home exterior (3-4 sides)',
    },
    {
      path: 'B',
      name: 'Less than half of the home exterior (1-2 sides)',
    },
    {
      path: 'C',
      name: 'The exterior trim',
    },
    {
      path: 'D',
      name: 'A deck or porch',
    },
    {
      path: 'E',
      name: 'A fence',
    },
    {
      path: 'F',
      name: 'Shutters and / or a front door',
    },
    {
      path: 'G',
      name: 'A garage exterior',
    },
    {
      path: 'H',
      name: 'A garage door',
    },
    {
      path: 'I',
      name: 'Other',
    },
  ],
};
function Routes() {
  return (
    <Fragment>
      <Route exact path="/" render={() => <Home />} />
      <Route exact path="/success" render={() => <Success />} />
      <Route
        key={'custom-1'}
        path={newRoute.path}
        render={(props) => (
          <MainFormConditioned
            title={newRoute.title}
            data={newRoute.task}
            {...props}
          />
        )}
      />
      {mainRoutes.map(({ path, title, task }, index) => (
        <Route
          key={index}
          path={path}
          render={(props) => <MainForm title={title} data={task} {...props} />}
        />
      ))}
      {services.map((taskOid, index) => (
        <Route
          key={index}
          path={`/${taskOid}`}
          render={(props) => <GeneralForm {...props} />}
        />
      ))}
      <Route path="/dropin" render={() => <DropInForm />} />
    </Fragment>
  );
}

export default Routes;
