import Zips from 'zipcodes';
import _ from 'lodash';

const normalizeQuestionAnswer = (question) => {
  const data = JSON.parse(JSON.stringify([...question]));
  let finalObj = {};

  for (let {
    _attributes: { id, description, type },

    ...item
  } of data) {
    let temp = {
      _attributes: {
        id: id,
        description: normalize(description),
        type,
      },
    };
    finalObj[`${normalize(description)}`] = '';
    for (let answer of item.answer) {
      if (answer.isSelected) {
        finalObj[`${normalize(description)}`] =
          finalObj[`${normalize(description)}`].length > 0
            ? finalObj[`${normalize(description)}`].concat(`, ${answer._text}`)
            : finalObj[`${normalize(description)}`].concat(`${answer._text}`);
      }
    }

    console.log('final object', finalObj);
  }

  return finalObj;
};

const ping = async (oid, description, zip, clickId) => {
  console.log('data:', { oid, description, zip, clickId });

  let body = new FormData();
  body.append('url', 'https://api.angi.com/srx/api/v2/ping');
  body.append(
    'data',
    JSON.stringify({
      zipCode: zip,
      serviceID: parseInt(oid),
      isTest: false,
      isOwnedAndOperated: true,
      trafficSource: '1',
      affiliateSourceType: 1,
      affiliateSrCreatedAt: new Date().toISOString(),
      isInSession: true,
    })
  );
  return fetch('https://api.yourrepairexperts.com/server.php', {
    method: 'POST',
    body,
  });
};

const normalize = (str) => {
  if (!typeof str === 'string') return str;
  return str.replace('<', '&#60;').replace('>', '&#62;').replace('&', '&#38;');
};

const getAnswerById = (interviewList, findId, HomeOwner = false) => {
  let finalAnswer = '';
  for (let {
    _attributes: { id },

    ...item
  } of interviewList) {
    if (id === findId)
      for (let answer of item.answer) {
        if (answer.isSelected) {
          if (HomeOwner) {
            return answer._attributes.id === '10001' ? true : false;
          } else {
            finalAnswer = answer._text;
            return finalAnswer;
          }
        }
      }
  }
  return HomeOwner ? false : finalAnswer;
};

export const sendRequest = async (data) => {
  console.log('final data', data);
  let { oid, description } = data.task.task._attributes;
  let {
    contact: {
      firstName: { _text: FirstName },
      lastName: { _text: LastName },
      TrustedFormUrl,
      contactMethods: { contactMethod },
    },
    location: {
      city: { _text: City },
      addressLine1: { _text: Address },
      zip: { _text: ZipCode },
    },
  } = data.customer;
  let contacts = {
    PhoneNumber: contactMethod[0]._text,
    EmailAddress: contactMethod[1]._text,
  };
  let { _text: zip } = data.customer.location.zip;
  let response = await ping(
    parseInt(oid),
    normalize(description),
    zip,
    data.clickId
  );
  let _response = await response.json();
  console.log('response', _response);

  if (_response.success) {
    let PropertyType = getAnswerById(data.task.task.question, '7735');
    let body = new FormData();
    body.append('url', 'https://api.angi.com/srx/api/v2/post');
    body.append(
      'data',
      JSON.stringify({
        isTest: false,
        cjLeadKey: _response.cjLeadKey,
        thirdPartyLeadID: data.clickId,
        zipCode: ZipCode, //'60606',
        firstName: FirstName,
        lastName: LastName,
        address: Address,
        city: City,
        state: Zips.lookup(ZipCode)?.state,
        phoneNumber: contacts.PhoneNumber.replace('+1', ''),
        emailAddress: contacts.EmailAddress,
        timeFrame: getAnswerById(data.task.task.question, '80000'), //1-2 Weeks',
        subscribe: false,
        propertyType: _.isEmpty(PropertyType) ? 'Home/Residence' : PropertyType, //'Residential',
        homeOwner: getAnswerById(data.task.task.question, '501504', true),
        projectUrgency: getAnswerById(data.task.task.question, '90000'), //'Ready to hire',
        trafficSource: '10',
        tcpaConsentLanguage:
          "By clicking 'Click Here to Continue', I agree to Angi’s Terms and Privacy Policy, and that Angi and its service professionals, or parties acting on their behalf, may use automated technology and prerecorded messages to deliver marketing calls or texts regarding my project and future projects to the number I provided. Consent is not a condition of service. ",
        tcpaConsent: true,
        serviceID: parseInt(oid),
        trustedFormUrl: TrustedFormUrl,
        questionsAndAnswers: normalizeQuestionAnswer(data.task.task.question),
        angiAutoConsent: true,
        angiTCPAConsent: true,
      })
    );
    const resp = await fetch('https://api.yourrepairexperts.com/server.php', {
      method: 'POST',
      body,
    });
    return await resp.json();
  } else {
    return _response;
  }
};

export const logError = async (body) => {
  const resp = await fetch('https://logger.yourrepairexperts.com/index.php', {
    method: 'POST',
    body,
  });
  return await resp.json();
};
