import React from 'react';

const HomeAdvisorBadge = () => (
  <div className="homeadvisor-signature mt-3">
    {/* <Image src={Logo} /> */}
    <p style={{ textAlign: 'justify' }}>
      By clicking "Click Here to Continue", I agree to Angi’s{' '}
      <a
        href="https://url.us.m.mimecastprotect.com/s/NnygCwpE12CvqkKYT9iJIJpfvg?domain=legal.angi.com"
        target="_blank">
        Terms{' '}
      </a>
      and{' '}
      <a
        href="https://url.us.m.mimecastprotect.com/s/jR0_CxkVg8sL7DkrtwsBIytoPZ?domain=legal.angi.com"
        target="_blank">
        Privacy Policy
      </a>{' '}
      , and that Angi and its service professionals, or parties acting on their
      behalf, may use automated technology and prerecorded messages to deliver
      marketing calls or texts regarding my project and future projects to the
      number I provided. Consent is not a condition of service.
    </p>
  </div>
);
export default HomeAdvisorBadge;
